import React, { Fragment, useContext } from 'react'
import { useTable, useSortBy, useExpanded } from "react-table";

import { MenuContext } from '../../context'

import ArrowUp from '../../assets/arrow-up.inline.svg';
import ArrowDown from '../../assets/arrow-down.inline.svg';


const ProductTable = ({ columns, data, renderRowSubComponent }) => {
  const [controlledExpanded, setControlledExpanded] = React.useState({});
  const menuContext = useContext(MenuContext);

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
  } = useTable(
    {
      columns,
      data,
      useControlledState: (state) => {
        return React.useMemo(
          () => ({
            ...state,
            expanded: controlledExpanded
          }),
          [state]
        );
      },
    },
    useSortBy,
    useExpanded
  );

  const onSortKeyPress = (e) => {
    if(!e.target) {
      return false
    }

    if(
      !e.target.childNodes ||
      e.target.childNodes.length === 0
    ) return false
    if(!['', 'Space', 'Enter'].includes(e.key)) return false
    e.target.click()
    e.preventDefault()
    e.stopPropagation()
  }

  const onKeyPress = (e) => {
    if(!e.target) {
      return false
    }
    
    if(
      !e.target.childNodes ||
      e.target.childNodes.length === 0
    ) return false
    if(!['', 'Space', 'Enter'].includes(e.key)) return false
    // e.target.childNodes[0].click()
    e.target.click()
    e.preventDefault()
    e.stopPropagation()
  }

  // Render the UI for your table
  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => {
              return (
                <th
                  {...column.getHeaderProps({
                    className: column.collapse ? "collapse" : ""
                  })}
                  aria-sort={
                    column.isSorted ? (
                      column.isSortedDesc ? "descending" : "ascending"
                    ) : "none"
                  }
                >
                  {
                    !["ProductTerm", "Overpayments", "expander"].includes(column.id)
                    ? (
                      <div
                        className="headerTable"
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                        onClick={() => {
                          if (column.isSorted && !column.isSortedDesc) {
                            column.toggleSortBy(true)
                          } else {
                            column.toggleSortBy(false)
                          }
                        }}
                        onKeyPress={onSortKeyPress}
                        tabIndex="0"
                        role="button"
                        inert={menuContext.isMenuOpen ? "true" : null}
                      >
                        {column.render("Header")}
                        <span {...column.getHeaderProps({ className: "sortBlock" })}>
                          <ArrowUp
                            className={`${(column.isSorted && !column.isSortedDesc) ? 'sorted' : '' }`}
                            onClick={() => column.toggleSortBy(false)}
                          />
                          <ArrowDown
                            className={`${(column.isSorted && column.isSortedDesc) ? 'sorted' : '' }`}
                            onClick={() => column.toggleSortBy(true)}
                          />
                        </span>
                      </div>
                    ) : (
                      <div
                        className="headerTable"
                        {...column.getHeaderProps()}
                      >
                        {column.render("Header")}
                      </div>
                    )
                  }
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          const { key } = { ...row.getRowProps() };
          return (
            <Fragment key={key}>
              <tr>
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps({
                        className: cell.column.collapse ? 'collapse' : '',
                      })}
                      // TODO: replace inline-styles with className
                      style={{ borderBottom: row.isExpanded && '0px', cursor: 'pointer' }}
                      // getToggleRowExpandedProps override inline style,
                      // that's why extracted only onClick property
                      // onClick={row.getToggleRowExpandedProps()["onClick"]}
                      onKeyPress={onKeyPress}
                      onClick={() => {
                        if (row.isExpanded) {
                          setControlledExpanded({})
                        } else {
                          setControlledExpanded({ [row.id]: true })
                        }
                      }}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
              {row.isExpanded ? (
                <tr>
                  <td colSpan={visibleColumns.length}>
                    {renderRowSubComponent({ row })}
                  </td>
                </tr>
              ) : null}
            </Fragment>
          );
        })}
      </tbody>
    </table>
  );
}

export default ProductTable
