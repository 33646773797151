import React, { Fragment, useState, useEffect, useContext } from "react";

import Tabs from "../tabs/tabs.component";
import GeneralButton from "../generalButton/generalButton.component";

import { MenuContext } from "../../context";

const ProductFilter = ({ data, setData, setIsFilterEmpty }) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const [filter, setFilter] = useState({
    Availability: "",
    ProductType: [],
    BookingFee: "",
    Cashback: "",
    "Free Legals for Remortgage": "",
    MaxLTV: "",
    ProductTerm: "",
    "Energy Efficient Homes": "",
    "LL > £2,000,000.01": "",
  });
  const [code, setCode] = useState("");

  const menuContext = useContext(MenuContext);

  useEffect(() => {
    // reset filter and data on tab change
    setFilter({
      Availability: "",
      ProductType: [],
      BookingFee: "",
      Cashback: "",
      "Free Legals for Remortgage": "",
      MaxLTV: "",
      ProductTerm: "",
      "Energy Efficient Homes": "",
      "LL > £2,000,000.01": "",
    });
    setData([]);
    setIsFilterEmpty(true);
    setCode("");
  }, [selectedTabIndex, setData, setIsFilterEmpty]);

  const onSelectChange = (event) => {
    const { name, value } = event.target;
    let newFilter = Object.assign(
      {},
      {
        ...filter,
        [name]: value,
      }
    );
    setFilter(newFilter);
  };

  const onProductTypeChange = (event) => {
    const {
      name, // "ProductType"
      value, // "Fixed", "Tracker" or "Discount"
    } = event.target;

    let valuePosIndex = filter[name].indexOf(value);

    if (valuePosIndex > -1) {
      // filter exists, then remove it
      let newFilter = filter;
      newFilter[name].splice(valuePosIndex, 1);
      setFilter(Object.assign({}, { ...filter, [name]: newFilter[name] }));
    } else {
      let newFilter = Object.assign(
        {},
        {
          ...filter,
          [name]: filter[name].concat([value]),
        }
      );
      setFilter(newFilter);
    }
  };

  const onProductFeeChange = (event) => {
    const {
      name, // "BookingFee"
      value, // "Include all", "No booking fee"
    } = event.target;

    let newFilter = Object.assign(
      {},
      {
        ...filter,
        [name]: value,
      }
    );
    setFilter(newFilter);
  };

  const onProductCashbackChange = (event) => {
    const {
      name, // "Cashback"
      value, // "Cashback available", "No cashback"
    } = event.target;

    let newFilter = Object.assign(
      {},
      {
        ...filter,
        [name]: value,
      }
    );
    setFilter(newFilter);
  };

  const onFreeLegalsForRemortgageChange = (event) => {
    const {
      name, // "Free Legals for Remortgage"
      value, // "Fee Assisted Legal Fees for Remortgage - Yes", "Fee Assisted Legal Fees for Remortgage - No"
    } = event.target;

    let newFilter = Object.assign(
      {},
      {
        ...filter,
        [name]: value,
      }
    );
    setFilter(newFilter);
  };

  const onEnergyEfficientHomesChange = (event) => {
    const {
      name, // "Energy Efficient Homes"
      value, // "Energy Efficient Homes - Yes", "Energy Efficient Homes - No"
    } = event.target;

    let newFilter = Object.assign(
      {},
      {
        ...filter,
        [name]: value,
      }
    );
    setFilter(newFilter);
  };

  const onLargeLoanChange = (event) => {
    const { name, value } = event.target;

    let newFilter = Object.assign(
      {},
      {
        ...filter,
        [name]: value,
      }
    );
    setFilter(newFilter);
  };

  const onFilter = () => {
    let newData = data.filter((product) => {
      let isAvailability =
        (filter["Availability"] === "" && filter["Energy Efficient Homes"] === "") ||
        (filter["Availability"] === "" && filter["Energy Efficient Homes"] !== "") ||
        (filter["Availability"] !== "" &&
          filter["Energy Efficient Homes"] === "" &&
          product["Availability"] === filter["Availability"]) ||
        (product["Availability"].includes(filter["Availability"]) && product["Availability"].includes("EEH")) ||
        (filter["Availability"] !== "" &&
          filter["Energy Efficient Homes"] === "No" &&
          product["Availability"] === filter["Availability"]) ||
        (product["Availability"] === filter["Availability"] && !product["Availability"].includes("EEH"));
      // product["Availability"].includes(filter["Availability"]))
      let isProductType =
        filter["ProductType"].length === 0 || filter["ProductType"].some((d) => product["ProductType"].includes(d));
      let isBookingFee =
        filter["BookingFee"] === "" ||
        (filter["BookingFee"] === "Include all" && Number(product["ProductFee"]) !== 0) ||
        (filter["BookingFee"] === "No booking fee" && Number(product["ProductFee"]) === 0);
      let isCashback =
        filter["Cashback"] === "" || product["Cashback"].toLowerCase().includes(filter["Cashback"].toLowerCase());
      let isFreeLegalsForRemortgage =
        filter["Free Legals for Remortgage"] === "" ||
        product["Free Legals for Remortgage"]
          .toLowerCase()
          .includes(filter["Free Legals for Remortgage"].toLowerCase());
      let isMaxLTV = filter["MaxLTV"] === "" || product["MaxLTV"].includes(filter["MaxLTV"]);
      let isProductTerm = filter["ProductTerm"] === "" || product["ProductTerm"].includes(filter["ProductTerm"]);
      let isEnergyEfficientHomes =
        (filter["Energy Efficient Homes"] === "" && product["Availability"].includes(filter["Availability"])) ||
        (filter["Energy Efficient Homes"] === "Yes" && product["Availability"].includes("EEH")) ||
        (filter["Energy Efficient Homes"] === "No" && !product["Availability"].includes("EEH"));
      let isLargeLoan =
        filter["LL > £2,000,000.01"] === "" ||
        product["LL > £2,000,000.01"].toLowerCase() === filter["LL > £2,000,000.01"].toLowerCase();
      return (
        isAvailability &&
        isProductType &&
        isBookingFee &&
        isMaxLTV &&
        isProductTerm &&
        isCashback &&
        isFreeLegalsForRemortgage &&
        isEnergyEfficientHomes &&
        isLargeLoan
      );
    });

    setData(newData);
    setIsFilterEmpty(false);
  };
  const onSearch = () => {
    let newData = data.filter((product) => product["NewProductCode"] === code);
    setData(newData);
    setIsFilterEmpty(false);
  };

  return (
    <Tabs
      activeTabIndex={selectedTabIndex}
      setActiveTab={setSelectedTabIndex}
      additionalClassName="tabs--product-finder"
      tabs={["By feature", "By product code"]}
      tabContent={[
        [
          <Fragment key={1}>
            <div
              className="row"
              id="panel-0"
              role="tabpanel"
              aria-labelledby="tab-0"
              tabIndex="0"
              inert={menuContext.isMenuOpen ? "true" : null}
            >
              <div className="col-xs-12 col-lg-6">
                <div className="field field--select">
                  {/* Customer type */}
                  <label htmlFor="Availability">Customer type</label>
                  <div className="select-wrapper">
                    <select
                      id="Availability"
                      name="Availability"
                      // value={customerType}
                      value={filter["Availability"]}
                      onChange={onSelectChange}
                      style={{ display: "block" }}
                      inert={menuContext.isMenuOpen ? "true" : null}
                    >
                      <option rel="option" value="">
                        Please select
                      </option>
                      <option rel="option" value="FTB">
                        First time buyer
                      </option>
                      <option rel="option" value="HM">
                        Homemover
                      </option>
                      <option rel="option" value="RM">
                        Remortgage
                      </option>
                      <option rel="option" value="ECBM">
                        Existing Mortgage Customer Borrowing More
                      </option>
                      <option rel="option" value="S">
                        Existing Mortgage Customer Switching Rate
                      </option>
                      <option rel="option" value="BTL - FTB - HM">
                        Buy to Let Purchase
                      </option>
                      <option rel="option" value="BTL - RM">
                        Buy to Let Remortgage
                      </option>
                      <option rel="option" value="BTL - ECBM">
                        Buy to Let Existing Customer Borrowing More
                      </option>
                      <option rel="option" value="BTL - S">
                        Buy to Let Existing Customer Switching Rate
                      </option>
                      <option rel="option" value="IBTL - FTB - HM-S">
                        International Buy to Let
                      </option>
                      <option rel="option" value="IBTL - RM">
                        International Buy to Let Remortgage
                      </option>
                      <option rel="option" value="FTB - HM - RM - ECBM -S">
                        International Residental
                      </option>
                      <option rel="option" value="IRM">
                        Remortgage International Range
                      </option>
                    </select>
                  </div>
                </div>

                {/* Product type */}
                <div className="field-group">
                  <fieldset>
                    <legend>Product type</legend>
                    <div className="d-md-flex justify-md-between">
                      <div className="field field--checkbox">
                        <input
                          name="ProductType"
                          id="ProductTypeFixed"
                          type="checkbox"
                          checked={filter["ProductType"].includes("Fixed")}
                          onChange={onProductTypeChange}
                          value="Fixed"
                          inert={menuContext.isMenuOpen ? "true" : null}
                        />
                        <label htmlFor="ProductTypeFixed">Fixed</label>
                      </div>
                      <div className="field field--checkbox">
                        <input
                          name="ProductType"
                          id="ProductTypeTracker"
                          type="checkbox"
                          checked={filter["ProductType"].includes("Tracker")}
                          onChange={onProductTypeChange}
                          value="Tracker"
                          inert={menuContext.isMenuOpen ? "true" : null}
                        />
                        <label htmlFor="ProductTypeTracker">Tracker</label>
                      </div>
                      <div className="field field--checkbox">
                        <input
                          name="ProductType"
                          id="ProductTypeDiscount"
                          type="checkbox"
                          checked={filter["ProductType"].includes("Discount")}
                          onChange={onProductTypeChange}
                          value="Discount"
                          inert={menuContext.isMenuOpen ? "true" : null}
                        />
                        <label htmlFor="ProductTypeDiscount">Discount</label>
                      </div>
                    </div>
                  </fieldset>
                </div>

                {/* Booking fee */}
                <div className="field-group">
                  <fieldset>
                    <legend>Booking fee</legend>
                    <div className="d-md-flex justify-md-between">
                      <div className="field field--radio">
                        <input
                          name="BookingFee"
                          id="BookingFeeAll"
                          type="radio"
                          checked={filter["BookingFee"] === "Include all"}
                          onChange={onProductFeeChange}
                          value="Include all"
                          inert={menuContext.isMenuOpen ? "true" : null}
                        />
                        <label htmlFor="BookingFeeAll">Include all</label>
                      </div>
                      <div className="field field--radio">
                        <input
                          name="BookingFee"
                          id="BookingFeeNo"
                          type="radio"
                          checked={filter["BookingFee"] === "No booking fee"}
                          onChange={onProductFeeChange}
                          value="No booking fee"
                          inert={menuContext.isMenuOpen ? "true" : null}
                        />
                        <label htmlFor="BookingFeeNo">No booking fee</label>
                      </div>
                    </div>
                  </fieldset>
                </div>

                {/* Cashback */}
                <div className="field-group">
                  <fieldset>
                    <legend>Cashback</legend>
                    <div className="field field--radio">
                      <input
                        name="Cashback"
                        id="CashbackAvailable"
                        type="radio"
                        checked={filter["Cashback"] === "Yes"}
                        onChange={onProductCashbackChange}
                        value="Yes"
                        inert={menuContext.isMenuOpen ? "true" : null}
                      />
                      <label htmlFor="CashbackAvailable">Cashback available</label>
                    </div>
                    <div className="field field--radio">
                      <input
                        name="Cashback"
                        id="CashbackNo"
                        type="radio"
                        checked={filter["Cashback"] === "No"}
                        onChange={onProductCashbackChange}
                        value="No"
                        inert={menuContext.isMenuOpen ? "true" : null}
                      />
                      <label htmlFor="CashbackNo">No cashback</label>
                    </div>
                  </fieldset>
                </div>

                {/* Free Legals for Remortgage */}
                <div className="field-group">
                  <fieldset>
                    <legend>Fee Assisted Legal Fees for Remortgage</legend>
                    <div className="d-md-flex justify-md-between">
                      <div className="field field--radio">
                        <input
                          name="Free Legals for Remortgage"
                          id="FeeAvailable"
                          type="radio"
                          checked={filter["Free Legals for Remortgage"] === "Yes"}
                          onChange={onFreeLegalsForRemortgageChange}
                          value="Yes"
                          inert={menuContext.isMenuOpen ? "true" : null}
                        />
                        <label htmlFor="FeeAvailable">Yes</label>
                      </div>
                      <div className="field field--radio" style={{ marginRight: "27%" }}>
                        <input
                          name="Free Legals for Remortgage"
                          id="NoFeeAvailable"
                          type="radio"
                          checked={filter["Free Legals for Remortgage"] === "No"}
                          onChange={onFreeLegalsForRemortgageChange}
                          value="No"
                          inert={menuContext.isMenuOpen ? "true" : null}
                        />
                        <label htmlFor="NoFeeAvailable">No</label>
                      </div>
                    </div>
                  </fieldset>
                </div>

                {/* Energy Efficient Homes */}
                <div className="field-group">
                  <fieldset>
                    <legend>Energy Efficient Homes</legend>
                    <div className="d-md-flex justify-md-between">
                      <div className="field field--radio">
                        <input
                          name="Energy Efficient Homes"
                          id="EnergyEfficientHomesAvailable"
                          type="radio"
                          checked={filter["Energy Efficient Homes"] === "Yes"}
                          onChange={onEnergyEfficientHomesChange}
                          value="Yes"
                          inert={menuContext.isMenuOpen ? "true" : null}
                        />
                        <label htmlFor="EnergyEfficientHomesAvailable">Yes</label>
                      </div>
                      <div className="field field--radio" style={{ marginRight: "27%" }}>
                        <input
                          name="Energy Efficient Homes"
                          id="NoEnergyEfficientHomesAvailable"
                          type="radio"
                          checked={filter["Energy Efficient Homes"] === "No"}
                          onChange={onEnergyEfficientHomesChange}
                          value="No"
                          inert={menuContext.isMenuOpen ? "true" : null}
                        />
                        <label htmlFor="NoEnergyEfficientHomesAvailable">No</label>
                      </div>
                    </div>
                  </fieldset>
                </div>

                {/* Large Loans */}
                <div className="field-group">
                  <fieldset>
                    <legend>Loan amount over £2,000,000.01</legend>
                    <div className="d-md-flex justify-md-between">
                      <div className="field field--radio">
                        <input
                          name="LL > £2,000,000.01"
                          id="LargeLoanAvailable"
                          type="radio"
                          checked={filter["LL > £2,000,000.01"] === "Yes"}
                          onChange={onLargeLoanChange}
                          value="Yes"
                          inert={menuContext.isMenuOpen ? "true" : null}
                        />
                        <label htmlFor="LargeLoanAvailable">Yes</label>
                      </div>
                      <div className="field field--radio" style={{ marginRight: "27%" }}>
                        <input
                          name="LL > £2,000,000.01"
                          id="NoLargeLoanAvailable"
                          type="radio"
                          checked={filter["LL > £2,000,000.01"] === "No"}
                          onChange={onLargeLoanChange}
                          value="No"
                          inert={menuContext.isMenuOpen ? "true" : null}
                        />
                        <label htmlFor="NoLargeLoanAvailable">No</label>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>

              <div className="col-xs-12 col-lg-6">
                <div className="field field--select">
                  {/* Maximum LTV */}
                  <label htmlFor="MaxLTV">Maximum LTV</label>
                  <div className="select-wrapper">
                    <select
                      name="MaxLTV"
                      id="MaxLTV"
                      value={filter["MaxLTV"]}
                      onChange={onSelectChange}
                      style={{ display: "block" }}
                      inert={menuContext.isMenuOpen ? "true" : null}
                    >
                      <option value="">Please select</option>
                      <option value="95%">95%</option>
                      <option value="90%">90%</option>
                      <option value="85%">85%</option>
                      <option value="80%">80%</option>
                      <option value="75%">75%</option>
                      <option value="70%">70%</option>
                      <option value="65%">65%</option>
                      <option value="60%">60%</option>
                    </select>
                  </div>
                </div>
                <div className="field field--select">
                  {/* Product term */}
                  <label htmlFor="ProductTerm">Product term</label>
                  <div className="select-wrapper">
                    <select
                      id="ProductTerm"
                      name="ProductTerm"
                      value={filter["ProductTerm"]}
                      onChange={onSelectChange}
                      style={{ display: "block" }}
                      inert={menuContext.isMenuOpen ? "true" : null}
                    >
                      <option value="">Please select</option>
                      <option value="2 Year">2 Year</option>
                      <option value="3 Year">3 Year</option>
                      <option value="5 Year">5 Year</option>
                      <option value="10 Year">10 Year</option>
                      <option value="LifeTime">LifeTime</option>
                    </select>
                  </div>
                </div>
                <GeneralButton
                  additionalClass="product-find-btn"
                  type="button"
                  isRed
                  onClick={onFilter}
                >
                  Find product
                </GeneralButton>
              </div>
            </div>
          </Fragment>,
        ],
        [
          <React.Fragment key={2}>
            <div className="by-product-code" id="panel-1" role="tabpanel" aria-labelledby="tab-1">
              <label htmlFor="Code">Product code</label>
              <div className="field field--input field--inner-submit field--product-code">
                <input
                  id="Code"
                  style={{ display: "block" }}
                  name="Code"
                  type="text"
                  onChange={(event) => setCode(event.target.value)}
                  value={code}
                  placeholder="Enter product code"
                  inert={menuContext.isMenuOpen ? "true" : null}
                />
                <span className="design-border"></span>
                <GeneralButton type="submit" onClick={onSearch} ariaLabel="Filter the products">
                  <span></span>
                </GeneralButton>
              </div>
              <GeneralButton
                additionalClass="product-find-btn"
                type="button"
                isRed
                onClick={onSearch}
              >
                Find product
              </GeneralButton>
            </div>
          </React.Fragment>,
        ],
      ]}
    />
  );
};

export default ProductFilter;
